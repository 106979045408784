var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',{staticClass:"line-head"},[_vm._v(" Selected Dispatch: "+_vm._s(_vm.selectedDispatchNumber)+" ")]),_c('div',{staticClass:"line-head"},[_vm._v("Dispatch Date: "+_vm._s(_vm.selectedDispatchDate))]),_c('div',{staticClass:"line-head"},[_vm._v("Customer: "+_vm._s(_vm.selectedDispatchCustomer))]),_c('div',[_c('vue-good-table',{attrs:{"id":"selected_dispatch","columns":_vm.columnsSelectedDispatch,"rows":_vm.rowsSelectedDispatch,"styleClass":"vgt-table striped bordered","theme":"black-rhino","fixed-header":true,"search-options":{
					enabled: false,
				},"pagination-options":{
					enabled: false,
					mode: 'records',
					perPage: 100,
					position: 'bottom',
					perPageDropdown: [5, 10, 15, 20, 100],
					dropdownAllowAll: false,
					setCurrentPage: 1,
					jumpFirstOrLast: true,
					firstLabel: 'First',
					lastLabel: 'Last',
					nextLabel: 'next',
					prevLabel: 'prev',
					rowsPerPageLabel: 'Rows per page',
					ofLabel: 'of',
					pageLabel: 'page', // for 'pages' mode
					allLabel: 'All',
				}},on:{"on-row-dblclick":_vm.onRowDoubleClick,"on-cell-click":_vm.onCellClick},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'invoice_price')?_c('span',[_c('p',{staticClass:"text-bold text-green text-align-right"},[_vm._v(" "+_vm._s(_vm.numeral(props.row.invoice_price / 100).format('0,0.00'))+" ")])]):(props.column.field === 'dispatch_amount')?_c('span',[_c('p',{staticClass:"text-bold text-purple text-align-center"},[_vm._v(" "+_vm._s(props.row.dispatch_amount)+" ")])]):(props.column.field === 'vat_credit')?_c('span',[_c('p',{staticClass:"text-bold text-blue text-align-right"},[_vm._v(" "+_vm._s(_vm.numeral(props.row.vat_credit / 100).format('0,0.00'))+" ")])]):(props.column.field === 'line_price_total_credit')?_c('span',[_c('p',{staticClass:"text-bold text-align-right"},[_vm._v(" "+_vm._s(_vm.numeral(props.row.line_price_total_credit / 100).format( '0,0.00' ))+" ")])]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])}),_c('div',{staticClass:"line-foot"},[_c('span'),_vm._v(" Lines Total: "),_c('span',{staticStyle:{"margin-left":"20px"}},[_vm._v(_vm._s(_vm.numeral(_vm.linesTotal / 100).format('0,0.00')))])]),_c('div',{staticClass:"line-foot"},[_vm._v(" Vats Total: "),_c('span',{staticStyle:{"margin-left":"20px"}},[_vm._v(_vm._s(_vm.numeral(_vm.vatsTotal / 100).format('0,0.00')))])]),_c('div',{staticClass:"line-foot"},[_vm._v(" Order Total: "),_c('span',{staticStyle:{"margin-left":"20px"}},[_vm._v(_vm._s(_vm.numeral(_vm.orderTotal / 100).format('0,0.00')))])])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }